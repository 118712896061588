import React, {useContext} from "react"
import CommittedCounter from "./committedCounter"
import cartTableStyles from '../../styles/carrello/cart-table.module.scss'
import CloseIcon from "../../assets/close.svg"
import { StoreContext } from "../../context/storeContext"
import {getFotoHeight, getFotoWidth,  getFotoThumbnailHash} from '../../utilities/murrinaUtils'

const CartTable = ({committedCart,  cssClass}) => {
  const { removeItemFromCommittedCart } = useContext(StoreContext)
  
  return ( 
      <div className={cssClass}>
         {committedCart.map(item => ( item.finitura ? 
         <div key={`${item.strapiId}-${item.colore}-${item.finitura}`} className={cartTableStyles.row}>
         <div  className={cartTableStyles.fotoBox}>

             <img className={cartTableStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL}${item.foto_luce[0].hash}`}  loading="lazy"/> 

         </div>
      <div className={cartTableStyles.name}>{item.nome} | {item.colore.trim().replace(/_/g, ' ')} | {item.finitura.trim().replace(/_/g, ' ')}</div>
        <CommittedCounter
           product={item}
           showCounter ={item.promo ? false: true}
           cssClassCounter={cartTableStyles.counter}
           cssClassBg={cartTableStyles.bg}
           cssClass={cartTableStyles.wrapper}
           cssClassBig={cartTableStyles.big}
           cssClassQty={cartTableStyles.qty}
           cssClassTotal={cartTableStyles.itemTotal}
           cssClassBigBtn={cartTableStyles.bigBtn}
           cssClassBigBtnLabel={cartTableStyles.bigBtnLabel}
         />
         <div className={cartTableStyles.closeItem} onClick={() => removeItemFromCommittedCart(item)}><CloseIcon className={cartTableStyles.icon} /></div>
       </div>       
         : 
          <div key={`${item.strapiId}-${item.color}`} className={cartTableStyles.row}>
            <div  className={cartTableStyles.fotoBox}>
            {(getFotoHeight(committedCart, item) >= getFotoWidth(committedCart, item)) ?
                <img className={cartTableStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL}${getFotoThumbnailHash(committedCart, item)}`}  loading="lazy"/> : 
                <img className={cartTableStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL_ROTATE}${getFotoThumbnailHash(committedCart, item)}`} loading="lazy"/>}
            </div>
         <div className={cartTableStyles.name}>{item.nome} | {item.color.trim().replace(/_/g, ' ')}</div>
          <CommittedCounter
              showCounter ={item.promo ? false: true}
              product={item}
              cssClassCounter={cartTableStyles.counter}
              cssClassBg={cartTableStyles.bg}
              cssClass={cartTableStyles.wrapper}
              cssClassBig={cartTableStyles.big}
              cssClassQty={cartTableStyles.qty}
              cssClassTotal={cartTableStyles.itemTotal}
              cssClassBigBtn={cartTableStyles.bigBtn}
              cssClassBigBtnLabel={cartTableStyles.bigBtnLabel}
            />
            <div className={cartTableStyles.closeItem} onClick={() => removeItemFromCommittedCart(item)}><CloseIcon className={cartTableStyles.icon} /></div>
          </div>
        ))}
      </div>
  )
}

export default CartTable
import React, { useContext } from "react"
import { formatPrice } from "../../utilities/formatPrice"
import { StoreContext } from "../../context/storeContext"

const CommittedCounter = ({
  product,
  showCounter,
  cssClass,
  cssClassCounter,
  cssClassBg,
  cssClassBig,
  cssClassQty,
  cssClassTotal,
  cssClassBigBtn,
  cssClassBigBtnLabel,
}) => {
  const { committedCart, addQtyToCommittedCart, removeQtyFromCommittedCart } = useContext(StoreContext)
  const productCount = committedCart.find(el => el.finitura ? el.strapiId === product.strapiId && el.colore.trim() === product.colore.trim() && el.finitura.trim() === product.finitura.trim() : el.strapiId === product.strapiId && el.color.trim() === product.color.trim())
    ? committedCart.find(el => el.finitura ? el.strapiId === product.strapiId && el.colore.trim() === product.colore.trim() && el.finitura.trim() === product.finitura.trim() : el.strapiId === product.strapiId && el.color.trim() === product.color.trim()).quantity
    : 0
  const productTotalPrice = productCount * product.prezzo

  return (
    <>
      <div className={cssClass}>
        <div className={cssClassCounter}>
        {showCounter &&  <button
            onClick={() => removeQtyFromCommittedCart(product)}
            className={cssClassBigBtn}
          >
            <span
              className={cssClassBigBtnLabel}
            >
              -
            </span>
          </button>}

          <span className={cssClassQty}>
            {productCount} {!showCounter && ` (ultimo pezzo rimasto)`}
          </span>
          {showCounter && <button
            onClick={() => addQtyToCommittedCart(product)}
            className={cssClassBigBtn}
          >
            {" "}
            <span
              className={cssClassBigBtnLabel}
            >
              +
            </span>
          </button>}
        </div>
        <span className={cssClassTotal}>
          tot.{" "}
          <span className={cssClassBig}>
            {formatPrice(productTotalPrice)}
          </span>
        </span>
      </div>
      <div className={cssClassBg}></div>
    </>
  )
}

export default CommittedCounter
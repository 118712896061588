import React, { useContext } from "react"
import { StoreContext } from "../context/storeContext"
import cartStyles from '../styles/pages/carrello.module.scss'
import { formatPrice } from "../utilities/formatPrice"
import Layout from "../components/common/layout"
import CartTable from "../components/carrello/cartTable"
import {Link} from 'gatsby'



const Carrello = ({ location }) => {
  const { committedCart, cartCount, getCartTotal } = useContext(StoreContext)
  const cartTotal = getCartTotal(committedCart)

  return (
    <Layout
      pageMeta={{
        title: "Carrello",
        keywords: ["La Murrina", "Borgomanero", "lampadari", "illuminazione", "lampade", "orologi", "oggettistica", "gioielli"],
        description:
          "Carrello del negozio online La Murrina Borgomanero",
        link: "/carrello",
      }}
      location={location}> 
        {committedCart.length === 0 && <p className={cartStyles.text}>Il tuo carrello è vuoto, torna allo shop</p>}
        <div className={cartStyles.layoutPage}>
          <div className={cartStyles.layoutCart}>
                {committedCart.length !== 0 &&  
              ( <><h3 className={cartStyles.categoryTitle}>i pezzi che hai scelto</h3>
              <CartTable cssClass={cartStyles.cartTable} committedCart={committedCart} /> </>)}         
              {committedCart.length !== 0 && <h3 className={cartStyles.totalRight}>totale da pagare {committedCart && formatPrice(cartTotal)}</h3>}
              {committedCart.length !== 0 && <p className={cartStyles.totalRightNote}>tutti i prezzi includono l'IVA</p>}
              {committedCart.length !== 0 && <div className={cartStyles.btnWrapper}><Link className={cartStyles.btn} to="/checkout">Prosegui al Checkout</Link> </div>}
          </div>
        </div>
      </Layout>
  )
}

export default Carrello